import { MiniAppInstance, SpaProps } from '@nab/x-spa-react';
import React, { useMemo } from 'react';
import { AppError, AppLoading } from '../../components/common';
import { RootWrapper } from '../../components/RootWrapper';
import Header from '../../components/Header';
import { getApplication, getAuthStrat, getIsMultiLogin, setBearerToken } from 'utils/sessionStorage';
import { GLOBAL_EVENTS } from 'utils/constants';
import Footer from 'components/Footer/Footer';
import { AppViewport, LoginWrapper, MiniappWrapper, SpaWrapper, LoginContainer, Background, StyledButton, SwitchContainer, Spacer, ParagraphPadding, LoginHeaderContainer } from 'components/common/styles';
import { LoginSwitcher, LoginSwitcherString } from 'utils/helpers';
import { H1, Paragraph } from '@nab/nui-react';

const Spa: React.FC<SpaProps> = ({ actions, activeAppInstance }) => {
    const viewport = useMemo(
        () => (
            <MiniappWrapper>
                <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
            </MiniappWrapper>
        ),
        [activeAppInstance]
    );
    const LoginViewPort = useMemo(
        () => (
            <LoginWrapper>
                <LoginContainer tenant={activeAppInstance?.appData?.tenant}>
                    {activeAppInstance?.appData?.method ? (
                        <LoginHeaderContainer tenant={activeAppInstance?.appData?.tenant}>
                            <H1>Log in to NAB</H1>
                            <Spacer />
                            <Paragraph>Log in using your {activeAppInstance?.appData?.method}</Paragraph>
                        </LoginHeaderContainer>
                    ) : null}
                    {viewport}
                    {activeAppInstance?.appData?.method ? (
                        <SwitchContainer tenant={activeAppInstance?.appData?.tenant}>
                            or <StyledButton styledAsLink label={`Log in using ${LoginSwitcherString(activeAppInstance?.appData?.tenant)}`} variant={'primary'} onClick={() => {
                                window.location.href = window.location.origin + `/${getApplication()}/login/${LoginSwitcher(getAuthStrat())}`;
                            }} />
                        </SwitchContainer>
                    ) : null}
                </LoginContainer>
                <Background src={activeAppInstance?.appData?.backgroundImage} alt="" />
                <Footer />
            </LoginWrapper>
        ),
        [activeAppInstance]
    );
    //listen for authentication
    actions.addEventListener(GLOBAL_EVENTS.USER_AUTHENTICATED, data => {
        const application = getApplication();
        if (data) {
            setBearerToken(data.bearerToken)
        }
        //single login
        if (getIsMultiLogin() === 'false') {
            actions.navigate(application);
        }
        //multi login
        else {
            window.location.href = window.location.origin + `/${getApplication()}`;
        }
    });

    actions.addEventListener(GLOBAL_EVENTS.GLOBAL_ERROR, data => {
        console.log('GLOBAL_ERROR', data);
    });
    return (
        <RootWrapper>
            <SpaWrapper>
                <>
                    <Header actions={actions} />
                    {activeAppInstance?.appData?.tenant ? (
                        <>
                            {LoginViewPort}
                        </>
                    ) : (
                        <AppViewport>
                            {viewport}
                        </AppViewport>
                    )}
                </>
            </SpaWrapper>
        </RootWrapper >
    );
}
export default Spa;