import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { getApplication, getEnv } from 'utils/sessionStorage';
import nabLogoImage from '../../images/nab-logo.png';
import { HeaderContainer, Logo, Title } from './Header.styles';
import { ThemeConfig } from "../../auth/ThemeConfig";
import LogoutButton from 'components/LogoutButton/LogoutButton';


const Header: FC<{ actions: any }> = ({ actions }) => {
    const [themeConfig, setThemeConfig] = useState<ThemeConfig>({ pageTitle: '', pageTitleFeature: false, signOutFeature: false, signOutRoute: '' });

    const fetchThemeConfig = async () => {
        await axios.get(`config/${getApplication()}/theme-config.json`)
            .then(res => {
                const themeConfigData = res.data;
                const themeConfig: ThemeConfig = themeConfigData[`${getEnv()}`];
                setThemeConfig((themeConfig))
            });
    }
    
    useEffect(() => {
        fetchThemeConfig();
    }, [])

    useEffect(() => {
        window.document.title = themeConfig?.pageTitle;
    }, [themeConfig]);


    return (
        <HeaderContainer>
            <Logo src={nabLogoImage} alt="NAB - Innovation" />
            {themeConfig?.pageTitleFeature && (<Title>{themeConfig.pageTitle}</Title>)}
            {themeConfig?.signOutFeature && (
                <LogoutButton actions={actions} signOutRoute={themeConfig.signOutRoute} />
            )}
        </HeaderContainer>
    );
};

export default Header;
