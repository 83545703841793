import React, { useState } from 'react';
import { H1, InternalLinkItem, ListGroup, ListGroupItem } from '@nab/nui-react';
import { getApplication, setAuthStrat } from 'utils/sessionStorage';
import { IB, NABC } from 'utils/constants';
import { LoginOptionsWrapper, LoginSelectorContainer } from './LoginSelector.styles';
import LoginModal from 'components/LoginModal/LoginModal';
import { Spacer, StyledButton } from 'components/common/styles';

const LoginSelector = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    return (
        <LoginSelectorContainer>
            <H1>Log in to NAB</H1>
            <Spacer />
            <p>You can log in using your NAB Connect or Internet Banking details.</p>
            <Spacer />
            <LoginOptionsWrapper>
                <ListGroup>
                    <ListGroupItem>
                        <InternalLinkItem
                            label="Log in with NAB Connect"
                            caption="The NAB Connect User ID is a 10-digit number that starts with 620 or is your Preferred User ID if you set one."
                            link={`${getApplication()}/login/${NABC}`}
                            onClick={() => {
                                setAuthStrat(NABC);
                            }}
                        />
                    </ListGroupItem>
                    <ListGroupItem>
                        <InternalLinkItem
                            label="Log in with Internet Banking"
                            caption="Use the NAB ID you use to access your NAB Internet Banking or the NAB Mobile Banking app."
                            link={`${getApplication()}/login/${IB}`}
                            onClick={() => {
                                setAuthStrat(IB);
                            }}
                        />
                    </ListGroupItem>
                </ListGroup>
            </LoginOptionsWrapper>
            <Spacer />
            <StyledButton styledAsLink label="Not sure which one to choose?" variant={'primary'} onClick={() => {
                setShowModal(true);
            }} />
            {showModal ? <LoginModal setShowModal={setShowModal} /> : null
            }
        </LoginSelectorContainer>
    )
};
export default LoginSelector;