import React, { FC, useEffect, useState } from "react";
import { Button } from "@nab/nui-react";
import axios from "axios";
import { getRevokeUrl } from "utils/baseUrl";
import { getBearerToken, getClientId, getEnv, removeBearerToken } from "utils/sessionStorage";
import { ButtonContainer } from "./LogoutButton.styles";
import { GLOBAL_EVENTS } from "utils/constants";

interface ILogoutButton {
    actions: any
    signOutRoute: string;
}
const LogoutButton: FC<ILogoutButton> = ({ actions, signOutRoute }) => {
    const [valid, setValid] = useState<boolean>(false);

    const revokeToken = async () => {
        await axios.post(`${getRevokeUrl(getEnv())}/v1/idp/oauth/revoke`,
            {
                "token": getBearerToken(),
                "client_id": getClientId()
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
    }
    //revoke token and logout
    const handleClick = async () => {
        await revokeToken().then(() => {
            setValid(false);
            removeBearerToken();
            //from theme configs
            window.location.href = window.location.origin + `/${signOutRoute}`;
        });
    }

    //listen to global logout event from miniapp
    actions.addEventListener(GLOBAL_EVENTS.LOGOUT, async data => {
        //await this before .replace
        await revokeToken().then(() => {
            removeBearerToken();
            setValid(getBearerToken() !== null);
        })
        //move outside so user can return to landing even if no token.
        if (!data.func) {
            window.location.href = window.location.origin + `/${signOutRoute}`;
        }
    });

    //first load 
    useEffect(() => {
        setValid(getBearerToken() !== null);
    })

    return (
        <>
            {valid ? (
                <ButtonContainer>
                    <Button variant="primary" label="Log out" onClick={handleClick} />
                </ButtonContainer>
            ) : null}
        </>
    )
}
export default LogoutButton;
