import styled from "styled-components";

export const LoginOptionsWrapper = styled.div`
    width: 100%;
    height: 100%;
  p {
    line-height: 24px;
  }
`;
export const LoginSelectorContainer = styled.div`
    padding: 40px 50px 50px 35px;
`;


