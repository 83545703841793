import styled from 'styled-components';

const Container = styled.div`
    padding: 0 24px;

    @media (max-width: 768px) {
        padding: 10px 32px;
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: flex-start;
        padding: 0 40px;
    }
    @media (min-width: 992px) {
        padding-left: 0 107px;
    }
`;

const HeaderContainer = styled(Container)`
    position: fixed;
    background-color: #000000;
    height: 64px;
    width: 100%;

    @media (min-width: 992px) {
        height: 64px;
    }
`;

const Logo = styled.img`
    max-height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
    @media (min-width: 992px) {
        max-height: 46px;
        margin-top: auto;
        margin-bottom: auto;
    }
`;
const Title = styled.h1`
    font-family: SourceSansPro, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-size: 2.5vw;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    margin: auto 0px auto 30px;
    @media (min-width: 1400px) {
        font-size:34px;
    }
    @media (max-width: 768px) {
        font-size: 2.5vw;
        display: inline;
        margin-left: 10px;
    }
    @media (max-width: 576px) {
        font-size: 0;
        display: inline;
    }
`;
export { HeaderContainer, Logo, Title };
