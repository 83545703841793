import { Button, Paragraph } from "@nab/nui-react";
import styled from "styled-components";
import { IB, NABC } from "utils/constants";

export const FooterContainer = styled.div`
    width: 100%;
    height: 116px;
    background-color: black;
    padding: 30px 20px 30px 50px;
    display: flex;
    p {
        color: white;
    }
`;
export const SpaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
    overflow: hidden;
`;
export const AppViewport = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex: 1;
    overflow-y: auto;
    flex-wrap: wrap;
    > div {
        width: 100%;
    }
    margin-top: 64px;
\    @media (min-width: 992px) {
        margin-top: 64px;
    }
    // This ensures apps are no longer displayed once they start shutting down
    > [aria-hidden='true'] {
        display: none;
    }
`;
export const MiniappWrapper = styled.div`
    height: auto !important;
`;
export const LoginWrapper = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex: 1;
    overflow-y: auto;
    flex-wrap: wrap;
    margin-top: 64px;
    background: #F5F5F5;
`;
export const LoginContainer = styled.div<{ tenant: string }>(
    ({ tenant }) => `
    height: 100%;
    min-height:860px;
    width: 500px;
    min-width: 550px;
    z-index: 999;
    background: white;
   ${setStyle(tenant)}
`);
const setStyle = (tenant: string) => {
    if (tenant === NABC) {
        return `
        padding: 30px 60px 30px 30px;
    `;
    }
    return;
}
export const Background = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 860px;
    object-fit: cover;
    object-position: 45% 55%;
    z-index:1;
    flex: 0 0 0%;
    max-width: 100%;
`;
export const Spacer = styled.div`
  margin-bottom: 30px;
`;
export const StyledButton = styled(Button)`
    padding: 0px;
`;
export const SwitchContainer = styled.div<{ tenant: string }>(
    ({ tenant }) => `
    padding-top: 10px;
    ${ButtonPadding(tenant)}
`);
const ButtonPadding = (tenant: string) => {
    if (tenant === IB) {
        return `
        padding-left: 40px;
        `
    }
}
export const ParagraphPadding = styled(Paragraph)`
    padding: 15px 0px 0px 2px;
    margin-bottom: -20px;
`
export const LoginHeaderContainer = styled.div<{ tenant: string }>(
    ({ tenant }) => `
    ${tenant === IB ? `padding: 40px 0px 0px 35px; margin: auto;`: `padding: 10px 0px 25px 5px;`}
`);