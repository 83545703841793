/* eslint-disable class-methods-use-this, no-console */
import { IRUMStrategy } from '@nab/x-types';
import { RUMEventData, IRUMUserDataProvider } from '@nab/x-spa-react';
import { AppDRUMStrategy } from '@nab/nab-x-sdk-browser';
import { AppConfig } from '../auth/AppConfig';

export class LocalRumMonitoring implements IRUMStrategy {
    public emitEvent(eventData: RUMEventData, userDataProvider?: IRUMUserDataProvider): void {
        console.log('rum.emitEvent', eventData, userDataProvider?.getDataForError?.());
    }
}

export function makeRumStrategy(environment: string, config: AppConfig): IRUMStrategy {
    if (environment === 'local') {
        return new LocalRumMonitoring();
    }

    return new AppDRUMStrategy({
        libraryUrl: 'https://x.nab.com.au/static-shared/nab/js/appd/adrum-23.3.0.4265.js',
        configValue: {
            appKey: config.appDynamicsKey,
            adrumExtUrlHttp: 'http://x.nab.com.au/static-shared/nab/js/appd',
            adrumExtUrlHttps: 'https://x.nab.com.au/static-shared/nab/js/appd',
            beaconUrlHttp: 'http://syd-col.eum-appdynamics.com',
            beaconUrlHttps: 'https://syd-col.eum-appdynamics.com',
            xd: {
                enable: true
            },
            spa: {
                spa2: true
            }
        }
    });
}
