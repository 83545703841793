import React from 'react';
import { H1, Col, Row, ErrorFillIcon } from '@nab/nui-react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
`;

export function AppError({ app, error }) {
    return (
        <ErrorWrapper>
            <Col>
                <Row>
                    <H1 data-testid="errorHeading">Error loading: '{(app?.name) || ''}'</H1>
                    <ErrorFillIcon size="lg" color="primary" />
                </Row>
                <p>
                    {error?.message?.split?.('\n').map?.(m => (
                        <span key={m} data-testid="errorMessage">
                            {m}
                            <br />
                        </span>
                    ))}
                </p>
            </Col>
        </ErrorWrapper>
    );
}

export default AppError;
