import React, { FC } from 'react'
import { Button, Modal, Paragraph } from '@nab/nui-react';
import { ButtonPositioner, A } from './LoginModal.styles';

const LoginModal: FC<{ setShowModal(showModal: boolean): void }> = ({ setShowModal }) => {
    return (
        <Modal
            id="mySpinnerPopper"
            title="Which login option to use"
            alignment="left"
            size="small"
            hasClose
            hasBackdropClose
            hasEscapeClose
            onClose={() => setShowModal(false)}
        >
            <Paragraph>When you were invited to use this tool you should have discussed which login to use.<br /><br />
                If you are still unsure which to login to use, contact <A href="mailto:innovation@nab.com.au">innovation@nab.com.au</A></Paragraph>
            <ButtonPositioner>
                <Button variant='primary' label="Ok, got it" onClick={() => setShowModal(false)} />
            </ButtonPositioner>
        </Modal>
    )
}
export default LoginModal;