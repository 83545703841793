import React from "react";
import { GlobalStyle, Theme } from '@nab/nui-react';

interface RootWrapperProps {
    children: React.ReactNode;
}

export function RootWrapper(props: RootWrapperProps) {
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                {props.children}
            </Theme>
        </>
    )
}
