import styled from "styled-components";

export const ButtonPositioner = styled.div`
    float: right;
    margin: 100px 0px 0px 0px;
`;
export const A = styled.a`
    cursor: pointer;
    color: #c20000;
    text-decoration: underline;
`;
