import { APP, AUTHSTRAT, BEARER_TOKEN, CLIENTID, ENV, MULTILOGIN } from "utils/constants";

export const getBearerToken = (): string | null => sessionStorage.getItem(BEARER_TOKEN);
export const setBearerToken = (token: string) => sessionStorage.setItem(BEARER_TOKEN, token);
export const removeBearerToken = (): void | null => sessionStorage.removeItem(BEARER_TOKEN);

export const getEnv = (): string | null => sessionStorage.getItem(ENV);
export const setEnv = (env: string) => sessionStorage.setItem(ENV, env);

export const getApplication = (): string | null => sessionStorage.getItem(APP);
export const setApplication = (app: string) => sessionStorage.setItem(APP, app);

export const getClientId = (): string | null => sessionStorage.getItem(CLIENTID);
export const setClientId = (id: string) => sessionStorage.setItem(CLIENTID, id);

export const getAuthStrat = (): string | null => sessionStorage.getItem(AUTHSTRAT);
export const setAuthStrat = (isAuthenticated: string) => sessionStorage.setItem(AUTHSTRAT, isAuthenticated);

export const getIsMultiLogin = (): string | null => sessionStorage.getItem(MULTILOGIN);
export const setIsMultiLogin = (isEnabled: string) => sessionStorage.setItem(MULTILOGIN, isEnabled);
