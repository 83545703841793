import React from 'react';
import { Spinner } from '@nab/nui-react';
import { LoadingSlotProps } from '@nab/x-spa-react/module/components/MiniAppInstance';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppLoading: React.FC<LoadingSlotProps> = ({ app }) => {
  return (
    <SpinnerWrapper>
      <Spinner data-testid="Spinner" size="lg" message={`Loading ${app.name}...`} />
    </SpinnerWrapper>
  );
};

export default AppLoading;
