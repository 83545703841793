import { ContextConfig } from "@nab/x-spa-react";
import axios from "axios";
import { getIsMultiLogin } from "./sessionStorage";
import { IB, NABC } from "./constants";

export const spaContextProvider = (env: string, contextName: string) => async (): Promise<ContextConfig> => {
    //if url contains login fetch identity miniapp context root.
    if (window.location.toString().indexOf("login") != -1 && getIsMultiLogin() === 'true') {
        try {
            return (await axios.get(`config/${contextName}/context-root-identity-miniapp.json`)).data[env];
        } catch (e) {
            throw new Error(`Failed to retrieve context ${contextName}`);
        }
    }
    //else fetch application miniapp context root.
    try {
        return (await axios.get(`config/${contextName}/context-root.json`)).data[env];
    } catch (e) {
        throw new Error(`Failed to retrieve context ${contextName}`);
    }
};

export const LoginSwitcherString = (login: string) => {
    switch(login){
        case NABC:
            return 'Internet Banking';
        case IB:
            return 'Nab Connect';
    }
}
export const LoginSwitcher = (login: string) => {
    switch(login){
        case NABC:
            return IB;
        case IB:
            return NABC;
    }
}