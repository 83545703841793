import styled from 'styled-components';

export const ButtonContainer = styled.div`
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    @media (max-width: 768px) {
        display: inline;
        float:right;
    }
`;

