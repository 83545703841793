import { FooterContainer } from 'components/common/styles';
import React, { FC } from 'react';

const Footer: FC = () => {
    return (
        <FooterContainer>
            <p>© National Australia Bank Limited ABN 12 004 044 937 AFSL and Australian Credit Licence 230686</p>
        </FooterContainer>
    )
}
export default Footer;