/* eslint-disable no-console */
import { configMgr } from '@nab/nab-x-sdk-browser';
import { start, DafInMemoryCacheStrategy, DafAuthConfig, DefaultAuthConfig, DafExchangeStrategy, registerSpaComponent, SeedTokenProviderWrapperStrategy } from '@nab/x-spa-react';
import { memoize } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Spa from './Spa';
import axios from 'axios';
import { getAuthStrat, getBearerToken, setApplication, setAuthStrat, setClientId, setEnv, setIsMultiLogin } from 'utils/sessionStorage';
import { makeRumStrategy } from '../../utils/rumStrategy';
import LoginSelector from 'components/LoginSelector/LoginSelector';
import { AUTHSTRAT, IB, LOGIN_SELECTOR, NABC } from 'utils/constants';
import { spaContextProvider } from 'utils/helpers';

registerSpaComponent(LOGIN_SELECTOR, LoginSelector);

const launch = memoize(async (basePath: string, contextName: string, multiLogin: string) => {
    await configMgr.init();
    const envConfig = (await axios.get(`config.json`)).data;

    //set inital strat for miniapp loading, but get actual strat after login selector page.
    let nabTenant = sessionStorage.getItem(AUTHSTRAT) !== null ? getAuthStrat() : IB;
    if (window.location.pathname.split("/").pop() === NABC) {
        nabTenant = NABC;
    } else if (window.location.pathname.split("/").pop() === IB) {
        nabTenant = IB;
    }
    //get env
    const environment = envConfig.NABX_INNOVATION_SHELL_FRONTEND_ENV || 'local';
    //get auth strat by tenant name (should maybe be a default like in nabx-universal-shell)
    const appConfig = (nabTenant !== LOGIN_SELECTOR && await axios.get(`config/authStrategies/${nabTenant}/context.json`)).data[environment];


    //set session storage
    setEnv(environment);
    setApplication(contextName);
    setIsMultiLogin(multiLogin);
    setAuthStrat(nabTenant);
    setClientId(appConfig.clientId);

    const hostElement = document.createElement('div');
    //auth config from appconfig from context file.
    const authConfig: DefaultAuthConfig & DafAuthConfig = {
        tokenType: appConfig.tokenType,
        tokenSource: appConfig.tokenSource,
        context: appConfig.context,
        environment: appConfig.dafEnvironment,
        requestTokenOnLoad: false,
        additionalRequestHeaders: {
            'x-nab-tenant': nabTenant
        }
    };

    await start({
        // The HTML element that the x-spa will render the shell into
        hostElement: hostElement,

        // React component which provides the main layout for the shell
        rootComponent: Spa,

        // Named set of apps and menu items which live in this shell
        rootContextId: 'root',

        // this is the base URL that the shell is being hosted on
        // i.e. /landscape
        serverBasePath: basePath,

        // Default auth config inherited by all apps which don't explicitly override it
        authConfig: authConfig,

        // Auth strategies applicable to this shell (will most likely include the DafTokenExchangeStrategy
        authStrategies: [
            new SeedTokenProviderWrapperStrategy(() => Promise.resolve(getBearerToken()), {
                context: 'external',
                tokenType: 'Bearer',
                tokenSource: 'DAF'
            }),
            new DafExchangeStrategy(appConfig.dafEndpoint)
        ],

        // // Defines how tokens are cached & validated
        authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

        userConfig: {
            org: 'NAB',
            type: 'customer'
        },
        spaContextProvider: spaContextProvider(environment, contextName),

        servicesConfig: {
            rum: {
                strategy: makeRumStrategy(environment, appConfig)
            }
        }
    })
    return hostElement;
});

export function Shell(props) {
    const ref = useRef<HTMLDivElement>(null);
    const route = useRouteMatch();
    const { contextName, multiLogin } = props;
    useEffect(() => {
        if (ref.current) {
            launch(route.path, contextName, multiLogin).then((element) => {
                ref.current.appendChild(element);
            });
        }
    }, [route.path, contextName, multiLogin]);

    return <div ref={ref} />;
}
