import { RootWrapper } from 'components/RootWrapper';
import { Shell } from 'pages/Shell/Shell';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './fonts.css';
import './style.css';
import HomePage from 'pages/HomePage/HomePage';
import { createRoot } from 'react-dom/client';
import { CDR_DASHBOARD_ROUTE, NAB_CARBON_INSIGHT_TOOL_ROUTE } from 'utils/constants';

createRoot(document.getElementById('appRoot')).render(
    <React.StrictMode>
        <RootWrapper>
            <Router>
                <Switch>
                    <Route path={CDR_DASHBOARD_ROUTE.URL} component={() => <Shell contextName={CDR_DASHBOARD_ROUTE.CONTEXT_NAME} multiLogin={CDR_DASHBOARD_ROUTE.MULTILOGIN} />} />
                    <Route path={NAB_CARBON_INSIGHT_TOOL_ROUTE.URL} component={() => <Shell contextName={NAB_CARBON_INSIGHT_TOOL_ROUTE.CONTEXT_NAME} multiLogin={NAB_CARBON_INSIGHT_TOOL_ROUTE.MULTILOGIN} />} />
                    <Route path="/" component={() => <HomePage />} />
                </Switch>
            </Router>
        </RootWrapper>
    </React.StrictMode>
);
