import React, { FC } from 'react';
import { Spinner } from '@nab/nui-react';
import { ContentContainer } from './styles';
const HomePage: FC = () => {
    return (
        <ContentContainer>
            <Spinner />
        </ContentContainer>
    );
};
export default HomePage;
