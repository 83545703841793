import styled from 'styled-components';

export const Title = styled.div`
    font-family: 'NABImpact', 'SourceSansPro', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 70px;
    line-height: 4rem;
`;
export const ContentContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    margin: 0;
    color: white;
    transform: translate(-50%, -60%);
    text-align: center;
    animation: fadeIn 2s;
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

